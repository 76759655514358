import api from '../Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getTags(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'label', 'disabled'], data)

  return (await api()).get(`/performance/okrs/tags`, {
    params,
    ...signal,
  })
}

export async function updateTag(tagID, payload) {
  return (await api()).put(`/performance/okrs/tags/${tagID}`, payload)
}

export async function createTag(payload) {
  return (await api()).post(`/performance/okrs/tags/`, payload)
}
